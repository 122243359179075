import { lazy } from "react";
import AppIndexPage from "../chunk/jacket";
import { Navigate } from "react-router-dom";
const Error404 = lazy(() => import('../chunk/error/Error404'))
const SlotsPage = lazy(() => import('../chunk/slots/Slots'))
const BubblePage = lazy(() => import('../chunk/bubble/Bubble'))
const TestIFramePage = lazy(() => import('../chunk/testIframePage/TestIFramePage'))

const routes = [
    {
        path: '/',
        element: <AppIndexPage />,
        children: [
            {
                path: '/',
                element: <Navigate to={'slots'} />
            },
            {
                path: 'slots',
                element: <SlotsPage />,
                meta: {
                    title: 'slots download page',
                },
            },
            // {
            //     path: 'bubble',
            //     element: <BubblePage />,
            //     meta: {
            //         title: 'bubble download page',
            //     },
            // },
            // {
            //     path: 'testiframe',
            //     element: <TestIFramePage />,
            //     meta: {
            //         title: 'Test IFrame Page',
            //     },
            // },
            {
                path: '*',
                element: <Error404 />,
                meta: {
                    title: 'Error 404',
                },
            }
        ]
    }
]

export default routes